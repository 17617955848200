<template>
  <div class="hotel-conform">
    <section class="order-area banner-main-area card">
      <div class="card-header">
        <div class="container">
          <h1><img :src="`${speedSizeDomain}/assets/img/app/order-icon8.png`" alt="#" title="" />{{ $t("booking.hotel-details") }}</h1>
        </div>
      </div>
      <div class="body">
        <!-------- banner area start -------->
        <section class="banner-area">
            <div class="container">
              <div class="banner">
                <carousel :slides="bookedHotel.imageList" :showThumb="true" :numThumb="4" :defaultImage="destinationImage" :altText="bookedHotel.hotelName" v-show="bookedHotel.imageList.length>0"/>
              </div>
            </div>
          </section>
          <!-------- banner area stop -------->
      </div>
    </section>
    <!------ kremlin area start ------>
    <section class="kremlin-area">
      <div class="container">
        <!------- heading area start ------->
        <div class="heading">
          <h1>{{ bookedHotel.hotelName }}</h1>
          <div class="d-flex retingbox" v-if="bookedHotel.grade>2">
            <b-form-rating
              class="ui rating"
              v-model="bookedHotel.grade"
              variant="warning"
              locale="he"
              readonly
            ></b-form-rating>
          </div>
        </div>
        <!------- heading area stop ------->
        <!------ kremlin list area start ------->
        <div class="kremlin-list">
          <ul class="d-flex flex-row">
            <li>
              <figure class="kremlin-box">
                <img :src="`${speedSizeDomain}/assets/img/app/icon3.png`" alt="#" title="" />
                <figcaption>{{ bookedHotel.address }}</figcaption>
              </figure>
            </li>
            <li>
              <figure class="kremlin-box">
                <img :src="`${speedSizeDomain}/assets/img/app/icon2.png`" alt="#" title="" />
                <figcaption>{{bookedHotel.period}}</figcaption>
              </figure>
            </li>
            <li>
              <figure class="kremlin-box">
                <img :src="`${speedSizeDomain}/assets/img/app/icon1.png`" alt="#" title="" />
                <figcaption>{{personString}}</figcaption>
              </figure>
            </li>
          </ul>
        </div>
        <!------ kremlin list area stop ------->
      </div>
    </section>
    <!------ kremlin area stop ------>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormRating } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: {
    Carousel: () => import('@/components/productPage/atoms/Carousel'),
    BFormRating,
  },
  props: {
    bookedHotel: {
      type: Object,
      default: () => ({
        hotelPrice: 0,
        hotelName: '',
        address: '',
        personString: '',
        grade: 0,
        period: '',
        basisCode: '',
        supplements: [],
        hotels: [],
        imageList: [],
        translations: null,
      }),
    },
  },
  data() {
    return {
      transBaseName: '',
      transfer: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      devMode: 'GET_MODE',
    }),
    destinationImage() {
      if (!this.imageList) return '';
      const dest = this.product.destination_1;
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      const url = (matchedItem) ? this.destinationImages.find((d) => d.code === dest).imageUrls[0] : '';
      return url;
    },
    personString() {
      let adult = 0, child = 0, infant = 0;
      this.bookedHotel.hotels.forEach((room) => {
        adult += room.adult;
        child += room.child;
        infant += room.infant;
      });
      let str = ` ${this.$t('product-page.adult-number')}:${adult}`;
      if (child) str += `, ${this.$t('product-page.child')}:${child}`;
      if (infant) str += `, ${this.$t('product-page.infant')};${infant}`;
      return str;
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    const { translations } = this.bookedHotel;
    this.bookedHotel.hotels.forEach((room) => {
      room.translations = translations;
    });
    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      const { translations } = this.bookedHotel;
      // eslint-disable-next-line no-mixed-operators
      this.transBaseName = translations?.basisName[this.bookedHotel.basisCode][this.lang] || this.bookedHotel.hotels[0].roomBasis;
      this.transfer = '';
      this.bookedHotel.supplements.forEach((item, index) => {
        this.transfer += translations?.suppName[item.suppId][this.lang] || item.suppName;
        if (index < this.bookedHotel.supplements.length - 1) this.transfer += ', ';
      });
    },
  },
};
</script>

<style lang="less" scoped>
  .dangerPrice{
    color:red;
  }

  .bonauf-desktop {
    .hotel-conform {
      max-width: 100% !important;

      .card-header {
        .container {
          max-width: 780px;
          margin: auto;

          h1 {
            font-size: 32px;
            padding: 20px 0px;
          }
        }
      }

      .body {
        .banner-area {
          .container {
            max-width: 780px;
          }
        }
      }
      .kremlin-area {
        .container {
          max-width: 780px;
          margin: auto;
        }
      }
    }

  }
</style>

<style scoped>
.hotel_details_area .hotel_details_heading {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(39, 136, 210, 1) 0%,
    rgba(1, 98, 172, 1) 100%
  );
  padding: 15px 20px;
}
.hotel_details_area .hotel_details_heading h3 {
  margin: 0 0;
  padding: 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.hotel_details_area .hotel_details_body {
  padding: 35px 20px;
  border: 1px solid #949494;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 25px;
}
.hotel_details_body .hotel_details_price_box {
  width: 25%;
  padding: 20px 20px;
  border: 1px solid #949494;
  background: #f5f5f5;
  margin-bottom: 20px;
}
.hotel_details_body .hotel_details_div {
  width: 75%;
  box-sizing: border-box;
  border: 1px solid #949494;
  border-right: 0px;
  padding: 20px 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-bottom: 20px;
}

.hotel_details_contain_box {
  width: 60%;
  box-sizing: border-box;
  padding: 0 0;
}

.hotel_details_image_box {
  width: 40%;
  box-sizing: border-box;
  padding: 0 0;
}
.hotel_details_body .hotel_details_price_box ul {
  padding: 0 0;
  margin: 0 0;
  list-style: none;
}
.hotel_details_body .hotel_details_price_box ul li {
  position: relative;
  padding-top: 5px;
  padding-right: 24px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px;
}
.hotel_details_body .hotel_details_price_box ul li i {
  position: absolute;
  top: 0;
  right: 0;
  color: #2485cf;
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
}

.hotel_details_price_box h3 {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.hotel_details_contain_box h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 0;
}

.hotel_details_contain_box output {
  border: none;
  padding-top: 0px;
  margin-top: 0px;
}

.hotel_details_contain_box .b-rating .b-rating-star {
  display: inline-block;
  font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 0px;
}

@media (max-width: 479px) {
  .hotel_details_area .hotel_details_heading h3 {
    font-size: 20px;
  }
  .hotel_details_divmobail {
    margin-top: 10px;
  }
  .hotel_details_divmobail {
    display: block;
    box-sizing: border-box;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    padding: 8px;
  }
  .hotel_details_image_box {
    width: 100%;
  }
  .hotel_details_contain_box {
    width: 100%;
  }
  .hotel_details_contain_box h3 {
    font-size: 20px;
  }
  .hotel_details_contain_box h5 {
    font-size: 15px;
  }
  .hotel_details_price_box h5 {
    font-size: 20px;
  }
  .hotel_details_price_box li {
    font-size: 15px;
  }

}
</style>

<style>
  .bonauf-desktop .apparea .card .body .banner-area .thumbnails::after {
    width: 25%;
    height: calc(50% + 10px);
  }
</style>
